<template>
  <div class="page">
    <h2 class="main-title">{{title}}</h2>
    <v-row>
      <v-col cols="1" md="2">
        <SideBar/>
      </v-col>
      <v-col cols="11" md="10">
        <router-view/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import SideBar from '@/components/SideBar/SideBar.vue'
  import {mapMutations} from 'vuex'

  export default {
    name: 'Pasport',
    components: {
      SideBar
    },
    data () {
      return {
        title: 'Отчеты',
      }
    },
    methods: {
      ...mapMutations({
        menuMutations: 'login/menuMutations'
      }),
    },
    mounted() {
      this.menuMutations(this.$route.matched[0].name)
    }
  }
</script>

<style lang="scss">
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  .list {
    .header {
      @include flex-justify(space-between, $align: flex-start);
      margin-bottom: 25px;

      h3 {
        margin-bottom: 0;
      }
    }
  }
  .page {
    .side-bar {
      padding: 0;
      .side-link {
        display: block;
        text-decoration: none;
        @include font($base-color, 15px, 17px, 500);
        cursor: pointer;
        margin-bottom: 17px;

        &:hover, &.active {
          color: $blue;
        }
      }
    }
  }

</style>
